import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { CenterLayout, Rule, Section } from "../components/styled";
import { X } from "react-feather";
import ImageMasonry from "react-image-masonry";
import ImageGallery from "react-image-gallery";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
// let palette = ["#E8EAF0", "#A7B4A6", "#768A9A", "#376B90", "#2E4151"];

// const GallerySection = forwardRef(({ data, index, handleOpen, origin }, ref) => {
const GallerySection = ({ data, index, origin }) => {
    const { name, images } = data;
    const [open, setOpen] = useState({ state: false, index: 0 });

    const galleryImages = images.map((image) => {
        let imageData = image.image.childImageSharp.gatsbyImageData.images.fallback;
        return {
            src: origin + "/" + imageData.src,
            thumbnail: origin + "/" + imageData.srcSet.split(",")[1].split(" ")[0],
            thumbnailWidth: 250 * imageData.aspectRatio,
            thumbnailHeight: 250,
            title: image.title,
            description: image.description,
            imageData,
        };
    });
    const handleClick = (e) => {
        if (e.target.classList[0] === "react-image-gallery-container") {
            setOpen(false);
        }
    };

    return (
        <>
            <Section className="section" index={index}>
                <CenterLayout justifyContent={"center"}>
                    <h3 className="section-title">{name}</h3>
                    <Rule index={index + 1} />

                    <div className="image-grid">
                        {/* <Gallery images={galleryImages} enableImageSelection={false} /> */}
                        <ImageMasonry numCols={images.length > 4 ? 4 : images.length}>
                            {images.map((image, index) => (
                                <div
                                    onClick={() => setOpen({ state: true, index })}
                                    key={index}
                                    className="masonry-wrapper"
                                    onKeyDown={(e) =>
                                        e.key === "13" ? setOpen({ state: true, index }) : null
                                    }
                                    role="button"
                                    tabIndex={0}
                                >
                                    <GatsbyImage image={image.image.childImageSharp.gatsbyImageData} alt="" />
                                </div>
                            ))}
                        </ImageMasonry>
                    </div>
                </CenterLayout>
            </Section>
            <Modal
                // aria-labelledby="gallery-modal-title"
                // aria-describedby="gallery-modal-description"
                // className="gallery-modal"
                open={open.state}
                onClose={() => setOpen({ state: false, index: 0 })}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                    style: { backgroundColor: "rgba(0,0,0,0.8)" },
                }}
                onClickCapture={(e) => handleClick(e)}
            >
                <Fade in={open.state}>
                    <div className="react-image-gallery-container">
                        <X
                            size={42}
                            onClick={() => setOpen({ state: false, index: 0 })}
                            className="close-modal"
                        />

                        <ImageGallery
                            items={galleryImages.map((image) => ({
                                original: image.src,
                                thumbnail: image.thumbnail,
                                description: image.description ?? null,
                                originalTitle: image.title ?? null,
                            }))}
                            startIndex={open.index}
                        />
                    </div>
                </Fade>
            </Modal>
        </>
    );
    // });
};

export default GallerySection;
