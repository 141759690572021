import React, { useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
// import { GatsbyImage } from "gatsby-plugin-image";
// import { Waypoint } from "react-waypoint";
// import { ArrowUp } from "react-feather";

// import Modal from "@material-ui/core/Modal";
// import Backdrop from "@material-ui/core/Backdrop";
// import Fade from "@material-ui/core/Fade";

import Layout from "../components/Layout";
import { Sections } from "../components/styled";
import GallerySection from "../components/GallerySection";

const Gallery = (props) => {
    const sections = useStaticQuery(graphql`
        {
            markdownRemark(fileAbsolutePath: { regex: "/gallery.md/" }) {
                id
                frontmatter {
                    title
                    description
                    sections {
                        name
                        images {
                            title
                            description
                            image {
                                childImageSharp {
                                    gatsbyImageData(layout: CONSTRAINED)
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
    // console.log(sections.markdownRemark);
    // let image = sections.markdownRemark.frontmatter.sections[0].images[0].image;
    // console.log(image);
    // const [open, setOpen] = useState(false);
    // const [childData, setChildData] = useState(null);
    // const [selectedIndex, setSelectedIndex] = useState(0);
    // const [scrolledPast, setScrolledPast] = useState(false);
    const refArray = useRef([]);
    // const selectorRef = useRef(null);
    const topRef = useRef(null);

    // const handleOpen = (e, childData) => {
    //     e.preventDefault();
    //     console.log(childData);

    //     if (e.keyCode === "13") {
    //         setChildData(childData);
    //         return setOpen(true);
    //     }

    //     setChildData(childData);
    //     return setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    // const scrollToRef = (ref) => {
    //     window.scrollTo(0, ref.current.offsetTop);
    // };
    return (
        <main ref={topRef}>
            <Layout title="Gallery">
                <div className="gallery-container">
                    {/* <div
                        className={"to-top" + (scrolledPast ? " visible" : "")}
                        onClick={() => {
                            scrollToRef(topRef);
                            setSelectedIndex(0);
                        }}
                    >
                        <ArrowUp color="#262B30" />
                    </div> */}
                    {/* <Waypoint
                        onEnter={() => setScrolledPast(false)}
                        onLeave={() => setScrolledPast(true)}
                    > */}
                    {/* <SectionSelector ref={selectorRef}>
                        {sections &&
                            sections.markdownRemark.frontmatter.sections.map((section, index) => {
                                return (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            setSelectedIndex(index);
                                            scrollToRef(refArray.current[index]);
                                        }}
                                        className={selectedIndex === index ? "selected" : null}
                                        ref={(refArray.current[index] = React.createRef())}
                                    >
                                        <span>{section.name}</span>
                                    </div>
                                );
                            })}
                    </SectionSelector> */}
                    {/* </Waypoint> */}
                    <Sections
                        // rows={sections && sections.markdownRemark.frontmatter.sections.length}
                    >
                        {sections &&
                            sections.markdownRemark.frontmatter.sections.map((section, index) => {
                                return (
                                    <GallerySection
                                        data={section}
                                        index={index}
                                        key={index}
                                        origin={props.location.origin}
                                        // handleOpen={handleOpen}
                                        ref={refArray.current[index]}
                                    />
                                );
                            })}
                    </Sections>
                </div>
                {/* <Modal
                    aria-labelledby="gallery-modal-title"
                    aria-describedby="gallery-modal-description"
                    className="gallery-modal"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        {childData && (
                            <div className="gallery-modal-container">
                                <div className="image-container">
                                    <Img
                                        fluid={childData.image.childImageSharp.fluid}
                                        className="gallery-modal-image"
                                        imgStyle={{
                                            objectFit: "contain",
                                            objectPosition: "50% 50%",
                                        }}
                                    />
                                </div>
                                <div className="info">
                                    <div>
                                        <h2 id="gallery-modal-title">{childData.title}</h2>

                                        <p id="gallery-modal-description">
                                            {childData.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Fade>
                </Modal> */}
            </Layout>
        </main>
    );
};

export default Gallery;
